/** @jsx jsx */
import { css } from '@emotion/react'

export const customSection = css`
  top: 200px;
  position: absolute;
  z-index: 1;
  @media (max-width: 500px) {
    top: 0;
    margin-top: 150px;
  }
  @media (min-width: 500px) and (max-width: 850px) {
    top: 40px;
  }
  @media (min-width: 850px) and (max-width: 1550px) {
    top: 100px;
  }
  .typed-cursor {
    color: #0c79fe;
    font-weight: bold;
    font-size: 18px;
  }
  margin-top: 120px;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-right: 10px;
    margin-left: 10px;
  }
  > h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    @media (max-width: 500px) {
      font-size: 36px;
      line-height: 43.2px;
      text-align: left;
    }
  }
  > br {
    @media (max-width: 768px) {
      display: none;
    }
  }
  > p {
    margin-top: 24px;
    font-size: 14px;
  }

  > a {
    @media (max-width: 500px) {
      display: flex;
      align-self: flex-start;
      margin-bottom: 127px;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      align-self: center;
    }
  }
`
export const abAgency = css`
  height: 85vh;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (min-width: 500px) and (max-width: 850px) {
    height: auto;
    align-items: center;
  }
  @media (min-width: 500px) and (max-width: 850px) {
    text-align: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
  }
`
export const videoBg = css`
  background-color: #d0caca;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 88px;

  h1 {
    font-size: 64px;
    text-align: center;
    margin: 0;
  }

  .video {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  .videoMobile {
    display: block;
    height: 800px !important;
    position: relative;
    top: -63px;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
`
