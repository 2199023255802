/** @jsx jsx */
import { jsx } from '@emotion/react'

import { containerCard } from '../assets/styles/CardCarousel.styles'
import 'swiper/swiper-bundle.min.css'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import PeopleIcon from '../assets/images/LP-vertere/comunidade.svg'
import BulbIcon from '../assets/images/LP-vertere/lampada.svg'
import TrophyIcon from '../assets/images/LP-vertere/trofeu.svg'
import ChatIcon from '../assets/images/LP-vertere/messageIcon.svg'
import SearchIcon from '../assets/images/LP-vertere/searchIcon.svg'
import PersonIcon from '../assets/images/LP-vertere/avatarIcon.svg'


function CardCarousel() {
  return (
    <div css={containerCard}>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={0}
        loop={true}
        modules={[Navigation]}
        navigation={{
          prevEl: '.slider-prev',
          nextEl: '.slider-next',
        }}
        breakpoints={{
          851: {
            spaceBetween: 24,
          },
        }}
        className='swiper'
      >
        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div className='icon'>
              <img src={TrophyIcon} alt='ícone do troféu' />
            </div>

            <div className='text'>
              <h3>Competição Saudável</h3>
              <p>
                O mecanismo de competição das olimpíadas funciona como em um
                jogo, estimulando sempre a auto-superação e a excelência
                coletiva. Ele se dá de uma forma que, além do desenvolvimento
                individual, favorece o desenvolvimento de relaçoes de amizade e
                de comunidade.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div className='icon'>
              <img src={BulbIcon} alt='ícone de lâmpada' />
            </div>

            <div className='text'>
              <h3>Inovação no Ensino</h3>
              <p>
                As olimpíadas são uma boa porta para atividades inovadoras nas
                escolas, e se integram bem com abordagens transdisciplinares,
                com diferentes metodologias e com ferramentas tecnológicas
                diversas.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div className='icon'>
              <img src={PeopleIcon} alt='ícone de pessoas' />
            </div>

            <div className='text'>
              <h3>Relações e Redes</h3>
              <p>
                Além das habilidades cognitivas e metacognitivas, o processo
                olímpico inclui também a dimensão socioemocional. A interação
                entre participantes novatos, experientes, ex-participantes e
                professores cria processos de retorno, cooperação e
                complementaridade e constrói redes de aprendizado significativo.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div className='icon'>
              <img src={ChatIcon} alt='ícone de pessoas' />
            </div>

            <div className='text'>
              <h3>Partindo do Interesse</h3>
              <p>
                O mecanismo de competição das olimpíadas funciona como em um
                jogo, estimulando sempre a auto-superação e a excelência
                coletiva. Ele se dá de uma forma que, além do desenvolvimento
                individual, favorece o desenvolvimento de relações de amizade e
                de comunidade.
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div className='icon'>
              <img src={PersonIcon} alt='ícone de pessoas' />
            </div>

            <div className='text'>
              <h3>Aprendizagem Ativa</h3>
              <p>
                O aprendizado não é adquirido de forma passiva; quando resolve
                um problema, o aluno ativa, direciona e regula o próprio
                processo, aprendendo não só um assunto mas aprendendo a aprender
                e a gerenciar o próprio conhecimento
              </p>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='swiper-slide__container'>
            <div className='icon'>
              <img src={SearchIcon} alt='ícone de pessoas' />
            </div>

            <div className='text'>
              <h3>Investigação de Problemas </h3>
              <p>
                Os problemas não são meras aplicações de teoria, mas envolvem
                pensar por vários ângulos e desenvolver novas soluções, como são
                as situações da vida real. Nesse processo, o professor tem um
                papel essencial em orientar e aprimorar a investigação
                desenvolvida pelo próprio aluno.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <button className='btn slider-prev'>
        <div className='btn__icon'>
          <svg
            width='13'
            height='25'
            viewBox='0 0 13 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M0.317343 11.698L11.1507 0.789245C11.5737 0.363209 12.2597 0.363209 12.6828 0.789317C13.1058 1.21535 13.1058 1.90604 12.6827 2.33208L2.6154 12.4695L12.6828 22.6075C13.1058 23.0335 13.1058 23.7241 12.6827 24.1503C12.4712 24.3633 12.194 24.4697 11.9167 24.4697C11.6394 24.4697 11.3622 24.3633 11.1506 24.1501L0.317343 13.2408C0.114122 13.0362 0 12.7588 0 12.4694C0 12.1801 0.114122 11.9026 0.317343 11.698Z'
              fill='#212121'
            />
          </svg>
        </div>
      </button>

      <button className='btn slider-next'>
        <div className='btn__icon'>
          <svg
            width='13'
            height='25'
            viewBox='0 0 13 25'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M12.6827 11.698L1.84929 0.789245C1.42629 0.363209 0.740324 0.363209 0.317245 0.789317C-0.105761 1.21535 -0.105761 1.90604 0.317318 2.33208L10.3846 12.4695L0.317245 22.6075C-0.105761 23.0335 -0.105761 23.7241 0.317318 24.1503C0.528784 24.3633 0.806046 24.4697 1.08331 24.4697C1.36057 24.4697 1.63783 24.3633 1.84937 24.1501L12.6827 13.2408C12.8859 13.0362 13 12.7588 13 12.4694C13 12.1801 12.8859 11.9026 12.6827 11.698Z'
              fill='#212121'
            />
          </svg>
        </div>
      </button>
    </div>
  )
}

export default CardCarousel
