/** @jsx jsx */
import { Container } from 'react-bootstrap'
import { jsx } from '@emotion/react'

import { carrousel, how } from '../assets/styles/How.styles'
import CarouselOly from './carouselOlys'
import CardCarousel from './cardCarousel'

const Objective = () => {
  return (
    <section css={how}>
      <Container data-aos="fade-right">
        <h2>Promovendo experiências imersivas para a educação</h2>
        <p className='none'>
          Nosso objetivo é transformar a educação através de práticas
          pedagógicas ativas e investigativas. Acreditamos no poder
          transformador das olimpíadas e nos dedicamos a mostrar que elas são
          eficientes para democratizar o ensino de qualidade e desenvolver o
          potencial de alunos e professores.
        </p>
      </Container>
      <div data-aos="fade-left" css={carrousel}>
        <CardCarousel />
      </div>
      <Container style={{ marginTop: 112, marginBottom: 85, maxWidth: '100%', padding:0}}>
        <CarouselOly />
      </Container>
    </section>
  )
}

export default Objective
