/** @jsx jsx */
import { Container } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import studentNotebook from '../assets/images/LP-vertere/studentNotebook.png'
import {
  queroApoiar,
  queroApoiarContainer,
  impactStyle,
} from '../assets/styles/Impact.styles'
import CarouselImages from './carouselImage'
import { Link } from 'gatsby'
import LinkButton from './linkButton'

const Impact = () => {
  return (
    <section css={impactStyle}>
      <Container className='impact'>
        <div data-aos="fade-right" className='textContent'>
          <h2 className='titleDesktop'>
            Veja nosso <br /> Impacto no mundo
          </h2>
          <h2 className='titleMobile'>Nosso impacto no mundo</h2>
          <p>
            Para nós, o impacto social significa compartilhar conhecimento para
            acelerar a transformação social em todo o mundo.
          </p>
          <LinkButton label='Saiba Mais' url='/o-que-fazemos'></LinkButton>
        </div>
        <div data-aos="fade-left" className='img'>
          <img
            className='studentNotebook'
            width={575}
            src={studentNotebook}
            alt='Estudante olhando para notebook'
          />
        </div>
      </Container>
      <Container style={{maxWidth: '100%', padding: 0}}>
        <CarouselImages />
      </Container>
      <Container css={queroApoiarContainer}>
        <div data-aos="fade-right">
          <h3>
            Vamos unir esforços <br /> pela educação?
          </h3>

          <Link style={{ color: '#fff' }} css={queroApoiar} to='/quero-apoiar'>
            Quero Apoiar
          </Link>
        </div>
      </Container>
    </section>
  )
}

export default Impact
