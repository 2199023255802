/** @jsx jsx */
import { jsx } from '@emotion/react'
import { containerStyles } from '../assets/styles/ImageCarousel.styles'

import { FreeMode, Navigation } from 'swiper'
import linguistica from '../assets/images/LP-vertere/linguistica.svg'
import celeritas from '../assets/images/LP-vertere/celeritas.svg'
import pocket from '../assets/images/LP-vertere/pocket.svg'
import obecon from '../assets/images/LP-vertere/obecon.svg'
import sapientia from '../assets/images/LP-vertere/sapientia.svg'
import vitalis from '../assets/images/LP-vertere/vitalis.svg'
import { Swiper, SwiperSlide } from 'swiper/react'

const CarouselOly = () => {
  return (
    <div css={containerStyles}>
      <Swiper
        modules={[FreeMode, Navigation]}
        loop={true}
        freeMode={true}
        className='swiper-container'
        spaceBetween={76}
        slidesPerView={'auto'}
      >
        <SwiperSlide>
          <img src={linguistica} alt='Logo Olimpiadas linguistica' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={celeritas} alt='Logo Olimpiadas celeritas' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pocket} alt='Logo Olimpiadas pocket' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={obecon} alt='Logo Olimpiadas obecon' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={sapientia} alt='Logo Olimpiadas sapientia' />
        </SwiperSlide>
        <SwiperSlide>
          <img src={vitalis} alt='Logo Olimpiadas vitalis' />
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default CarouselOly
