/** @jsx jsx */
import { css } from '@emotion/react'

import studentCrowd from '../images/LP-vertere/student-crowd.png'
import studentCrowdMobile from '../images/LP-vertere/student-crowd-mobile.png'
import studentCrowdTablet from '../images/LP-vertere/student-crowd-tablet.png'
export const container = css`
  @media (max-width: 768px) {
    margin-left: 20px;
  }

  > a {
    @media (max-width: 500px) {
      margin-top: 30px !important;
      margin-bottom: 57px;
      font-size: 16px;
    }
  }
`

export const blockQuote = css`
  display: flex;
  justify-content: end;
  background: url(${studentCrowd});
  padding: 120px 90px;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 500px) {
    position: relative;
    top: -145px;
    background: url(${studentCrowdMobile});
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 0;
    height: 755px;
    width: 100%;
    justify-content: end;
    display: flex;
  }
  @media (min-width: 500px) and (max-width: 850px) {
    background: url(${studentCrowdTablet});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    min-height: 415px;
    display: flex;
    justify-content: end;
    div {
      margin-right: 30px;
      margin-top: 59px;
      width: 330px;
    }
  }
  h2 {
    color: white;
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
    width: 465px;
    text-align: left;
    @media (max-width: 768px) {
      font-size: 24px;
      width: 100%;
      text-align: left;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      font-size: 24px;
      width: 330px;
    }
  }
  p {
    color: white;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    margin-top: 24px;
    text-align: left;
    width: 443px;
    @media (max-width: 768px) {
      font-size: 16px;
      width: 100%;
      margin-right: 15px;
    }
    @media (min-width: 500px) and (max-width: 850px) {
      font-size: 16px;
      width: 330px;
    }
  }
  a {
    @media (min-width: 500px) and (max-width: 850px) {
      margin-bottom: 60px;
    }
  }

  > div {
    text-align: center;
    justify-content: end;
    display: flex;
    flex-direction: column;
  }
`
