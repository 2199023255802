/** @jsx jsx */
import { css } from '@emotion/react'

export const containerCard = css`
  align-items: center;
  column-gap: 8px;
  display: grid;
  grid-template-areas: 'btnPrev slider btnRight';
  grid-template-columns: auto minmax(auto, 1050px) auto;
  margin: auto;
  max-width: 1282px;
  padding: 0 8px;

  @media (max-width: 850px) {
    column-gap: 0;
    grid-template-areas:
      'slider slider slider'
      '_ btnPrev btnRight';
    padding: 0;
    row-gap: 40px;
  }
  .swiper {
    grid-area: slider;
    max-width: 1050px;
    width: 100%;

    &-slide {
      background: #eef0f8;
      border-radius: 16px;
      height: 450px !important;
      max-width: 334px;
      padding: 40px 24px 0;

      &__container {
        text-align: center;

        .icon {
          background-color: #4467a9;
          border-radius: 16px;
          height: 68px;
          margin: 0 auto 30px;
          padding: 10px;
          width: 68px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .text {
          h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 150%;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }

      @media (max-width: 850px) {
        &:first-of-type {
          margin-left: 37px;
        }

        &:last-of-type {
          margin-left: 8px;
          margin-right: 37px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-left: 8px;
        }
      }

      @media (max-width: 500px) {
        &:first-of-type {
          margin-left: 16px;
        }

        &:last-of-type {
          margin-left: 8px;
          margin-right: 16px;
        }

        &:nth-of-type(2),
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          margin-left: 8px;
        }
      }
    }
  }

  .btn {
    align-items: center;
    background: rgba(237, 240, 247, 0.99);
    border: none;
    border-radius: 80px;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    outline: none;
    width: 50px;

    &__icon {
      width: 100%;

      img {
        width: 100%;
      }
    }

    @media (max-width: 850px) {
      height: 36px;
      width: 36px;
    }

    @media (max-width: 500px) {
      background: inherit;
    }
  }
  .btn:hover {
    path {
      fill: #0c79fe;
    }
  }

  .slider-prev {
    grid-area: btnPrev;
    padding: 13px 20px 13px 17px;

    @media (max-width: 850px) {
      justify-self: end;
      margin-right: 40px;
      padding: 9px 14px 9px 12px;
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }

  .slider-next {
    grid-area: btnRight;
    justify-self: end;
    padding: 13px 17px 13px 20px;

    @media (max-width: 850px) {
      justify-self: start;
      margin-right: 58px;
      padding: 9px 12px 9px 14px;
    }

    @media (max-width: 500px) {
      margin-right: 20px;
    }
  }
`
