/** @jsx jsx */
import { css } from '@emotion/react'

export const carrousel = css`
  margin-top: 56px;
  @media (max-width: 850px) {
    padding-right: 0px !important;
  }
`

export const how = css`
  padding: 120px 0px 0;
  @media (max-width: 500px) {
    position: relative;
    top: -120px;
  }
  .none {
    @media (max-width: 768px) {
      display: none;
    }
  }
  h2 {
    font-size: 28px;
    margin-bottom: 24px;
    font-weight: 700;
    text-align: center;
  }
  p {
    line-height: 27px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 60px 0px 0px;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
  }

  img {
    width: 100%;
  }
`

export const blockTitle = css``
