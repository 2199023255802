/** @jsx jsx */
import { css } from '@emotion/react'

export const queroApoiarContainer = css`
  margin-top: 200px;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3 {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    width: 619px;
    font-weight: 700;
    @media (max-width: 500px) {
      font-size: 24px;
      width: 100%;
    }
  }
`

export const queroApoiar = css`
  background-color: #0c79fe;
  border-radius: 8px;
  color: '#fff';
  font-weight: 700;
  font-size: 16px;
  margin-top: 56px;
  margin-bottom: 200px;
  padding: 6px 13px 6px 13px;
  white-space: nowrap;
  :hover {
    background-color: #4766a8;
  }
`

export const impactStyle = css`
  .impact {
    display: flex;
    margin-bottom: 104px;
    justify-content: center;

    @media (max-width: 500px) {
      display: flex;
      flex-direction: column-reverse;
    }
    .studentNotebook {
      @media (max-width: 500px) {
        width: 100%;
        margin-bottom: 48px;
      }
      @media (min-width: 500px) and (max-width: 850px) {
        width: 360px;
      }

    }
    .titleMobile {
      display: none;
      @media (max-width: 850px) {
        display: inline-flex;
        color: #212121;
        font-size: 24px;
        font-weight: 700;
        line-height: 120%;
        
      }
      @media (min-width: 500px) and (max-width: 850px) {
        color: #4766a8;
      }
    }
    .titleDesktop {
      @media (max-width: 850px) {
        display: none;
      }
      @media (min-width: 500px) and (max-width: 850px) {
        font-size: 24px;
      }
    }
    p {
      width: 377px;
      padding: 0;
      margin: 0;
      font-size: 18px;
      line-height: 150%;
      text-align: left;
      color: #212121;
      @media (max-width: 500px) {
        font-size: 16px;
      }
      @media (min-width: 500px) and (max-width: 850px) {
        margin-right: 37px;
        width: 292px;
      }
    }
    h2 {
      font-size: 40px;
      margin-bottom: 24px;
      font-weight: 700;
      line-height: 120%;
      color: #4766a8;
    }

    .textContent {
      margin-right: 121px;
      @media (min-width: 500px) and (max-width: 850px) {
        margin-right: 37px;
        width: 292px;
      }
    }
  }

  
`

