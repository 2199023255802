/** @jsx jsx */
import { jsx } from '@emotion/react'

import { blockQuote, container } from '../assets/styles/Blockquote.styles'
import LinkButton from './linkButton'
const Blockquote = () => {
  return (
    <section css={blockQuote}>
      <div data-aos="fade-left" css={container}>
        <h2>Preparamos estudantes para as competências do século XXI</h2>
        <p>Conectamos pessoas e propósitos por meio da democratização do conhecimento. Saiba como desenvolvemos o potencial de jovens estudantes por meio das nossas Olimpíadas do Conhecimento.</p>
        <LinkButton marginTop={56} fontSize='18' fontWeight={700} label='Conheça Nossas Olimpiadas' url='/nossas-olimpiadas'/>
      </div>
    </section>
  )
}

export default Blockquote
