import React from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import SubHeader from '../components/sub-header'
import Header from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import Blockquote from "@/components/blockquote";
import ObjectiveSection from "@/components/objective-section";
import Impact from "@/components/impact";


const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Instituto Vertere">
          <Header />
          <SubHeader />
          <Blockquote />
          <ObjectiveSection />
          <Impact />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
