/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  abAgency,
  customSection,
  videoBg,
} from '../assets/styles/SubHeader.styles'
import LinkButton from './linkButton'
import { useEffect, useRef, useState } from 'react'
import Typed from 'typed.js'
import Video from './video-background'
import VideoMobile from './video-background-mobile'

const SubHeader = () => {
  const typedRef = useRef(null)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const options = {
      strings: [
        'acelerar a transformação social.',
        'impulsionar o conhecimento.',
        'promover a competição saudável.',
      ],
      typeSpeed: 30,
      backSpeed: 30,
      loop: true,
    }

    const typed = new Typed(typedRef.current, options)

    return () => {
      typed.destroy()
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500)
    }

    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 500)
      window.addEventListener('resize', handleResize)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return (
    <section css={[abAgency, videoBg]}>
      <div css={customSection}>
        <h1 data-aos='fade-right'>
          O poder de transformação das <br /> Olimpíadas do Conhecimento
        </h1>
        <p data-aos='fade-left'>
          Conectamos estudantes através da educação para{' '}
          <span ref={typedRef}></span>
        </p>
        <div data-aos='fade-right'>
          <LinkButton label='Saiba Mais' url='/nossas-olimpiadas' />
        </div>
      </div>

      {isMobile ? (
        <VideoMobile
          videoSrcURL='https://player.vimeo.com/video/844194146?h=e62c170602&api=1&background=1&autoplay=1&loop=1&autopause=0'
        />
      ) : (
        <Video
          videoSrcURL='https://player.vimeo.com/video/842853034?h=e42f98371f&api=1&background=1&autoplay=1&loop=1&autopause=0'
        />
      )}
    </section>
  )
}

export default SubHeader
