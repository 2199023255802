/** @jsx jsx */
import { css } from '@emotion/react'

export const containerStyles = css`
  display: flex;
.swiper-container .swiper-wrapper {
  transition-timing-function: linear;
}
  .swiper-slide {
    width: auto !important;
  }
  .swiper-slide-active{
    width: auto !important;
  }
`

export const imageContainer = css`
  height: 420px;
  display: flex;
  
`

export const sectionTwoImages = css`
  display: flex;
  flex-direction: column;
  height: 420px;
  width: 300px;
  margin-right: 20px;
  scroll-snap-align: start;
  > img {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 300px;
    border-radius: 8px;
  }
`
export const sectionOneImage = css`
  margin-right: 20px;
  scroll-snap-align: start;
`
