import React from 'react'

const VideoMobile = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className='videoMobile'>
    <iframe
      src={videoSrcURL}
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      frameBorder='0'
      webkitallowfullscreen='true'
      mozallowfullscreen='true'
      allowFullScreen
    />
  </div>
)
export default VideoMobile
