/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
  sectionTwoImages,
  sectionOneImage,
  containerStyles,
} from '../assets/styles/ImageCarousel.styles'

import participantes from '../assets/images/LP-vertere/participantes-sapientia.png'
import students from '../assets/images/LP-vertere/estudantes-bandeira-brasil.png'
import finalSapientia from '../assets/images/LP-vertere/finalSapientia.png'
import interview from '../assets/images/LP-vertere/entrevista.png'
import impactedStudents from '../assets/images/LP-vertere/estudantes-impactados.png'
import membrosOBL from '../assets/images/LP-vertere/membrosOBL.png'
import membrosSapientia from '../assets/images/LP-vertere/membrosSapientia.png'
import amountProjects from '../assets/images/LP-vertere/quantidade-de-projetos.png'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, FreeMode, Navigation } from 'swiper'
const Carousel = () => {
  

  return (
    <div css={containerStyles}>
      <Swiper
        className='swiper-container'
        modules={[Autoplay, FreeMode, Navigation]}
        loop={true}
        freeMode={true}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        speed={8000}
        slidesPerView={'auto'}
        
      >
        <SwiperSlide>
          <div css={sectionTwoImages}>
            <img src={participantes} alt='icone' />
            <img src={students} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionOneImage}>
            <img src={finalSapientia} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionTwoImages}>
            <img src={interview} alt='icone' />
            <img src={impactedStudents} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionOneImage}>
            <img src={membrosOBL} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionTwoImages}>
            <img src={membrosSapientia} alt='icone' />
            <img src={amountProjects} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionTwoImages}>
            <img src={participantes} alt='icone' />
            <img src={students} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionOneImage}>
            <img src={finalSapientia} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionTwoImages}>
            <img src={interview} alt='icone' />
            <img src={impactedStudents} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionOneImage}>
            <img src={membrosOBL} alt='icone' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div css={sectionTwoImages}>
            <img src={membrosSapientia} alt='icone' />
            <img src={amountProjects} alt='icone' />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Carousel
